import React from 'react';
import Helmet from 'react-helmet';

import AppContext, { UserContext } from "../../components/AppContext";
import NavBar from "../../components/NavBar/NavBar";
import { Footer } from "../../components/Footer/Footer";
import { Hero } from '../../components/AboutPro/Angular/Hero';
import { Hero3 } from '../../components/AboutPro/Angular/Hero3';
import { Hero2 } from '../../components/AboutPro/Angular/Hero2';
import { Hero4 } from '../../components/AboutPro/Angular/Hero4';
import { VideoSection } from '../../components/AboutPro/Angular/VideoSection';
import { FeaturedIn } from '../../components/FeaturedIn/FeaturedIn';
import { Feature } from '../../components/AboutPro/Angular/Feature';
import { PagesShowcase } from '../../components/AboutPro/Angular/PagesShowcase';
import { DocsPreview } from '../../components/AboutPro/Angular/DocsPreview';
import { Showcase } from '../../components/AboutPro/Angular/Showcase';
import { Pricing } from '../../components/AboutPro/Angular/Pricing';
import FAQ from '../../components/AboutPro/Angular/Faq';
import { Cta } from '../../components/AboutPro/Angular/Cta';
import '../../styles/scss/style.scss'

 const ContrastPro = () => {
   return (
    <AppContext>
      <Helmet>
        <title>Angular Bootstrap PRO UI kit and Template - Contrast</title>
        <meta name="title" content="Angular Bootstrap PRO UI kit and Template - Contrast" />
        <meta
          name="description"
           content="Angular Bootstrap Elegant PRO UI Kit and PRO reusable components for building mobile-first, responsive webistes and web apps"
        />
        <meta property="og:title" content="Angular Bootstrap PRO UI kit and Template - Contrast" />
        <meta
          property="og:description"
           content="Angular Bootstrap Elegant PRO UI Kit and PRO reusable components for building mobile-first, responsive webistes and web apps"
        />
        <meta property="twitter:title" content="Angular Bootstrap PRO UI kit and Template - Contrast" />
        <meta
          property="twitter:description"
           content="Angular Bootstrap Elegant PRO UI Kit and PRO reusable components for building mobile-first, responsive webistes and web apps"
        />
        <meta property="og:site_name" content="Devwares" />
         <meta name="twitter:card" content="summary_large_image" />
         <meta property="og:image" content="https://res.cloudinary.com/speedwares/image/upload/v1643031081/contrast-bootstrap_p456h2.jpg" />
         <meta name="twitter:image" content="https://res.cloudinary.com/speedwares/image/upload/v1643031081/contrast-bootstrap_p456h2.jpg" />

      </Helmet>
      <UserContext.Consumer>
        {({ user }) => (
          <div>
            <div className="container">
               <NavBar user={user} />
            </div>
               <Hero />
               <VideoSection />
               <div className="mt-5">
                 <FeaturedIn />
               </div>
               <Feature />
               <Hero3 />
               <Hero2 />
               <Hero4 />
               <PagesShowcase />
               <DocsPreview />
               <Showcase />
               <Pricing />
               <FAQ />
               <Cta />
               <div className="container">
                 <Footer />
               </div>
          </div>
        )}
      </UserContext.Consumer>
    </AppContext>
   );
 };

export default ContrastPro
