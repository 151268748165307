import React from 'react';

function FAQ() {
  const questions = [
    {
      id: 1,
      question: 'What version of bootstrap is Contrast built with',
      answer:
        'Contrast Angular Bootstrap is currently devleoped using the latest version of bootstrap which is bootstrap 5',
    },
    {
      id: 2,
      question: 'What version of Angular does Contrast support',
      answer:
        'Contrast Angular Bootstrap is currently being developed using Angular 15',
    },
    {
      id: 2,
      question: 'What is contained in the purchase',
      answer:
        'When you purchase any license, you get access to the full capabalities of the Contrast Angular Bootstrap UI Librray for building beautiful user interfaces. You will get a user manual which shows you how to install the premimum contrast UI lobaray in your project to start building all different types of inerfaces and also build any of the interfaces you see in the documentation by just copying the code. It also comes with a fully built premium template built using the contrast premium UI library which you can also customize to use with your project.',
    },
    {
      id: 3,
      question: 'How to get support',
      answer:
        'Support is so important to us because we understand how annoying it can be to have issues without knowing who to ask or constantly searching Google. You can easily reach out to us by sending a mail to contact@devwares.com or you can reach us through our contact form by clicking the contact link in the navigation above, or you can use the live chat by your right side below to reach us. We will be more than happy to help with any issues you have',
    },
    {
      id: 4,
      question: 'Where do I get the documentation for the libary',
      answer:
        'You can get the documentation for all the different components and combinations in the documentation page by clicking on the docs link the naviagtion above',
    },
    {
      id: 5,
      question: 'Do I get updates?',
      answer:
        'Yes you will, you just need to login into your account on devwares or gumroad and download the new update when it is released.',
    },
    {
      id: 6,
      question: 'What do you mean by Lifetime updates?',
      answer:
        'This means the duration of time you will continue to receive new updates on bug fixes and new components we are constantly adding to library. Purchasing a lifetime access license means you will get updates forever as the library or templates are being updated. You will be notified when this happens and you can download a new version either through your account on devwares or through gumroad',
    },
    {
      id: 7,
      question: 'Can I keep using the product after the license expired',
      answer:
        'Yes you can always keep using the product after the license has expired but if you will need a new license to keep getting new updates after the license expires',
    },
    {
      id: 8,
      question: 'Can I use the icons and images that comes with the template',
      answer:
        'Yes all icons included in the project are from open source libraries and the images are also free to use for both personal and commercial projects.',
    },
    {
      id: 9,
      question: 'What does the Personal/Startup/Small Business mean',
      answer:
        'The personal license only allows for one user to use the product. While the Startup license allows for 2 - 8 teammates. The small business license allows for 9 - 20 teammates',
    },
  ];

  const [searchTerm, setSearchTerm] = React.useState('');
  const [searchResults, setSearchResults] = React.useState([]);
  const handleSearchChange = e => {
    setSearchTerm(e.target.value);
  };

  React.useEffect(() => {
    const results = questions.filter(item => item.question.toLowerCase().includes(searchTerm));
    setSearchResults(results);
  }, [searchTerm]);

  return (
    <div className="contain">
      <h2 className="comparison__lead">Frequently Asked Questions</h2>
      <Searchbar onSearchChange={handleSearchChange} />
      <section className="faq">
        {searchResults.map(item => (
          <Question key={item.id} question={item.question} answer={item.answer} />
        ))}
      </section>
    </div>
  );
}

const Searchbar = props => {
  const [value, setValue] = React.useState('');
  const handleChange = e => {
    setValue(e.target.value.toLowerCase());
    props.onSearchChange(e);
  };
  return (
    <form className="form3">
      <svg viewBox="0 0 512 512" width="100px" title="search">
        <path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z" />
      </svg>
      <input
        className="searchbar"
        type="text"
        placeholder="Search for a question"
        onChange={handleChange}
        value={value}
      />
    </form>
  );
};

const Question = props => {
  const [isActive, setActive] = React.useState(true);
  const handleClick = id => {
    setActive(!isActive);
  };
  return (
    <div className="question-wrapper">
      <div onClick={() => handleClick(props.id)} className="question" id={props.id}>
        <strong>
          <h4 style={{ fontWeight: '700' }}>{props.question}</h4>
        </strong>
        <button onClick={() => handleClick(props.id)}>
          <svg
            className={isActive ? 'active' : ''}
            viewBox="0 0 320 512"
            width="100"
            title="angle-down"
          >
            <path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z" />
          </svg>
        </button>
      </div>
      <div className={isActive ? 'answer active' : 'answer'}>
        <h5>{props.answer}</h5>
      </div>
    </div>
  );
};

export default FAQ;
