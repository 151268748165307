import React from 'react';


export const Hero3 = () => {

  return (
    <div className="container">
      <div className="feature feature-2">
        <div className="feature__wrap">  
          <div className="feature__left">
            <h2 className="feature__lead feature__lead--1 showcase__lead">
              Get a Premium Angular Admin Dashboard template bundled in
            </h2>
            <p className="feature__text mb-5">
                In addition to the Angular bootstrap components and sections you get access to when you use CDBAngular PRO. We have also developed a premium Angular admin template with 27+ well crafted pages. This pages can help you get started very easily on UI projects without having to create them from scratch.

              <br />
              <br />
              Access 5 Angular dashboards, landing pages, Sign in and Sign up pages, 404 pages, Contact pages, About pages and so much more all bundled in with CDBAngular PRO.
          
             </p>
            <a target="_blank" rel="noreferrer" href="https://cdbreact-pro-demo.netlify.app/" className="btn1 btn__inverse">
              View dashboards
            </a>
          </div>
          <div className="feature__right">
            <img
              src={require('../../../components/images/adminheropro.png')}
              alt="#"
              className="feature__img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
