import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';


export const VideoSection = () => {
  return (
    <div className="text-light py-5" style={{ minHeight: '100vh', background: 'rgba(26,32,44,1)' }}>
      <div className="container">
        <div className="feature-3 mt-5">
          <div className="feature__wrap">
            <div className="mx-auto text-center">
              <h1 className="font-bold text-4xl mb-3 mx-auto" style={{ maxWidth: '800px' }}>
                Create landing pages and admin dashboards for your projects in minutes
              </h1>
              <p className="mb-4 mx-auto" style={{ maxWidth: '600px' }}>
                {' '}
                You just have to see all the beautiful, well crafted components and templates
                contrast ships with
              </p>
              <div className="flex item-center justify-center mb-5 mx-auto">
                <a target="_blank" href="https://cdbootstrap-demo.netlify.app/" rel="noreferrer">
                  <button className="btn btn-success mx-auto mr-4">Preview</button>
                </a>
                <AnchorLink href="#pricing">
                  <button className="btn btn-outline-success mx-auto">Download</button>
                </AnchorLink>
              </div>
              {/* <p className="mb-3 mx-auto"><small>There’s no way to describe it. You just have to see it for yourself.</small></p> */}
              <div>
                <video className="w-100 mx-auto" style={{ maxWidth: '920px' }} controls>
                  <source src={require('../../../components/images/demo1.mp4')} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
