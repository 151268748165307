import React from 'react';
import { Link } from 'gatsby';

import AnchorLink from "react-anchor-link-smooth-scroll";

export const Feature = () => {
  return (
    <div className="container">
    <div className="feature feature-2">
      <div className="feature__wrap">
        <div className="feature__left">
          <img
            src={require('../../../components/images/aboutlastfree.png')}
            alt="#"
            className="feature__img"
          />
        </div>
        <div className="feature__right">
          <h2 className="feature__lead feature__lead--1 showcase__lead">
            No more installing multiple libraries to get different components. CDBAngular PRO is a Robust UI library with all the UI components you will need.
          </h2>
          <p className="feature__text">
            Avoid manking your website or webapp cumbersome by installing different libraries to achieve different Angular UI components. CDBAngular PRO's component library has a collection of Angular components that can be combined together in more that 10000+ ways.
          <br /> <br />           
          These Angular bootstrap components has been well made to cover almost any type of webapp or website you will want to build. Which includes Angular boostrap components like advanced sidebar, multiselects, autocomplete, date and time pickers, datatables and so much more. All available in the CDBAngular pro library
            </p>
            <div className="feature__buttons">
              <Link to="/docs/contrast/angular/index" className="btn1 btn__inverse">
                <span className="btn__text">View Documentation</span>
              </Link>
            </div>
          
        </div>
      </div>
      </div>
    </div>
  );
};
